/*====================================================================================*/
 /* TABLE OF CONTENTS
/*====================================================================================*/
/* 01 RESET 
/* 02 GLOBAL SETTINGS  
/* 03 HEADER   
/* 04 SUBHEADERS 
/* 05 FILTER
/* 06 BIG MESSAGE
/* 07 PROPERTIES
/* 08 SERVICES
/* 09 AGENTS
/* 10 BLOG
/* 11 PAGES
/* 12 CONTACT
/* 13 CALL TO ACTION
/* 14 SIDEBAR
/* 15 SHORTCODES
/* 16 FOOTER

/*====================================================================================*/
 /* 01 RESET
/*====================================================================================*/

* { margin:0px; padding:0px; }

h1,h2,h3,h4 { margin-top:0px; }

ul li { list-style:none; }

/*====================================================================================*/
 /* 02 GLOBAL SETTINGS
/*====================================================================================*/

@font-face {
    font-family: 'Proxima Nova Light';
    src: url('type/proximanova-light-webfont.eot');
    src: url('type/proximanova-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('type/proximanova-light-webfont.woff') format('woff'),
         url('type/proximanova-light-webfont.ttf') format('truetype'),
         url('type/proximanova-light-webfont.svg#proxima_novalight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Reg';
    src: url('type/proximanova-regular-webfont.eot');
    src: url('type/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('type/proximanova-regular-webfont.woff') format('woff'),
         url('type/proximanova-regular-webfont.ttf') format('truetype'),
         url('type/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Semi-bold';
    src: url('type/proximanova-semibold-webfont.eot');
    src: url('type/proximanova-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('type/proximanova-semibold-webfont.woff') format('woff'),
         url('type/proximanova-semibold-webfont.ttf') format('truetype'),
         url('type/proximanova-semibold-webfont.svg#proxima_novasemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

html { width:100%; height:100%; }

body {
	font-family:Proxima Nova Light, Helvetica, Arial;
	font-size:15px;
	color:#464646;
	width:100%; 
	height:100%; 
}

h1 { font-family:Proxima Nova Light, Helvetica, Arial; }

h3 {
	font-family:Proxima Nova Light, Helvetica, Arial;
	color:$primary-dark-color;
	font-size:19px;
	padding-bottom:7px;
	border-bottom:7px solid $primary-dark-color;
	display:inline-block;
}

h4 {
	font-family:Proxima Nova Semi-bold, Helvetica, Arial;
	font-size:16px;
}

a { 
	color:$primary-dark-color;
	-webkit-transition: color 0.2s linear;
    -moz-transition: color 0.2s linear;
    -ms-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear;
}

a:hover { color:$light-secondary-action-hover; text-decoration:none; }

.divider {
	background-color:#d7d7d7;
	max-width:100%;
	height:2px;
	margin-top:-14px;
	margin-bottom:30px;
}

.divider.thin {
	height:1px;
	margin-top:15px;
	margin-bottom:15px;
}

.genericSection { padding:70px 0px 70px 0px; }

.primary-color {
	color: $primary-dark-color
}

/* BUTTONS */

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
    color: #fff;
    background-color: $secondary-action-color;
    border-color: $secondary-action-color;
}

.buttonColor {
	font-family:Open Sans;
	font-size:13px;
	letter-spacing:0.2px;
	padding:10px 35px 10px 35px !important;
	color:white;
	border-radius:5px;
	border:1px solid $border-color;
	box-shadow: inset 0 1px 1px white;
	text-shadow:0px -1px 3px #0e4562;
	background-color:$secondary-action-color;
	background: -webkit-linear-gradient($secondary-action-color, $light-secondary-action); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient($secondary-action-color, $light-secondary-action); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient($secondary-action-color, $light-secondary-action); /* For Firefox 3.6 to 15 */
	background: linear-gradient($secondary-action-color, $light-secondary-action); /* Standard syntax */
}

.buttonColor:hover {
	color:white;
	text-decoration:none;
	background: -webkit-linear-gradient($secondary-action-color, $light-secondary-action-hover); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient($secondary-action-color, $light-secondary-action-hover); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient($secondary-action-color, $light-secondary-action-hover); /* For Firefox 3.6 to 15 */
	background: linear-gradient($secondary-action-color, $light-secondary-action-hover); /* Standard syntax */
}

.buttonGrey {
	font-family:Open Sans;
	font-size:11.4px;
	letter-spacing:0.2px;
	padding:6px 27px 6px 27px !important;
	color:#868686;
	border-radius:5px;
	border:1px solid #cfcfcf;
	background-color:#f6f6f6;
	background: -webkit-linear-gradient(#f8f8f8, #f2f2f2); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(#f8f8f8, #f2f2f2); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(#f8f8f8, #f2f2f2); /* For Firefox 3.6 to 15 */
	background: linear-gradient(#ffffff, #f2f2f2); /* Standard syntax */
	-webkit-transition: color 0.2s linear;
    -moz-transition: color 0.2s linear;
    -ms-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear;
}

.buttonGrey:hover {
	text-decoration:none;
	color:#393939;
	background: linear-gradient(#f2f2f2, #ffffff); /* Standard syntax */
}

.buttonGrey.large {
	font-size:14px;
	padding:10px 30px 10px 12px !important;
	color:#868686;
}

.buttonGrey.large:hover { 
	color:#393939;
	background: linear-gradient(#f2f2f2, #ffffff); /* Standard syntax */
}

/** FORM ELEMENTS **/

.formDropdown, input {
	width:100%;
	padding:8px;
	margin-bottom:20px;
	border-radius:3px;
	border:none;
	border:1px solid #c9c9c9;
	box-shadow: 0 1px 1px white;
}

input[type="file"] {
	background:white;
	border:none;
	padding:5px 0px 5px 0px;
}

select {
	-webkit-appearance: none;  
    -moz-appearance: none;
    text-indent: 0.01px;
 	text-overflow: "";
 	background: url('../images/dropdown-button.png') no-repeat;
 	background-position: right -1px;
 	background-color:white;
 	cursor:pointer;
}

/* Firefox select button fix */
@-moz-document url-prefix() {
    select {
    	background-position: 89% -1px;
    	background-image: url('../images/dropdown-button2.png');
    }
    .formDropdown { width:111%; }
    .formBlock.select { overflow:hidden; }
}

select::-ms-expand {
display: none;
}

label {
	font-family:Open Sans;
	color:#787878;
	font-size:13px;
}

textarea { height:130px; width:100% !important; }

.icon {
	float:left;
	margin-right:10px;
	padding-top:0px;
}

/*====================================================================================*/
 /* 03 HEADER 
/*====================================================================================*/

/* TOP BAR */
/***********************/
.topBar {
	padding:7px 0px 7px 0px;
	min-height:36px;
	border-bottom:1px solid #e8e8e8;
	background-color:#f6f6f6;
	background: -webkit-linear-gradient(#f8f8f8, #f2f2f2); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(#f8f8f8, #f2f2f2); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(#f8f8f8, #f2f2f2); /* For Firefox 3.6 to 15 */
	background: linear-gradient(#f8f8f8, #f2f2f2); /* Standard syntax */
}

.topBarText { 
	font-family:Open Sans;
	color:#8e8e8e;
	font-size:11px;
	float:left; 
	margin-top:3px;
	margin-right:20px; 
	margin-bottom:0; 
}

.socialIcons { float:right; margin-bottom:0; }
.socialIcons li {
	display:inline;
	padding-left:10px;
	padding-right:6px;
	border-left:1px solid #e2e2e2;
}

.topBarText a { color:#8e8e8e; }

.socialIcons li a {
	-webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -ms-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.socialIcons li a:hover { opacity:0.6; }

.socialIcons .topBarText {
	display:none;
	float:none;
	margin-right:0px;
}


/* MAIN HEADER */
/***********************/
.dropdown:hover .dropdown-menu {
    display: block;
 }

 .navbar.navbar-default.navbar-fixed-top { 
 	background-color:white;
	border:none; 
	box-shadow: 0px 0px 3px #9c9c9c;
	z-index:1000;
}

.navbar-toggle { margin-top:17px; }

.navbar-brand { 
	margin-top:6px; 
	padding: 0 
}
.navbar-brand span { font-family:Open Sans; color:$nav-color; }
.navbar-brand img { margin-right:15px; padding-bottom:3px; }

.nav.navbar-nav { float:right; }

.nav.navbar-nav li a {
	font-family:Proxima Nova Reg;
	color:#686868;
	font-size:12.5px;
	letter-spacing:0.4px;
	padding-top:26px;
	padding-bottom:26px;
	margin-right:8px;
	-webkit-transition: color 0.2s linear;
    -moz-transition: color 0.2s linear;
    -ms-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear;
}

.nav.navbar-nav li a:hover {
	background-color:transparent;
	color:$nav-color;
}

.nav.navbar-nav li:hover .sub-menu { display:block; }

.nav.navbar-nav .current { box-shadow: inset 0 -4px 0px $nav-color; }
.nav.navbar-nav .current-sub { background-color:#f4f4f4; color:$nav-color; }

.nav.navbar-nav .dropdown .dropdown-menu {
	box-shadow: 0px 2px 3px #9c9c9c;
	background-color:white;
	border:none;
	border-radius:0px;
	padding:0px;
	margin-top:0px;
}

.nav.navbar-nav .dropdown .dropdown-menu li a {
	font-family:Proxima Nova Semi-bold;
	margin:0px 0px 0px 0px;
	padding:10px;
	color:#868686;
	font-size:12px;
	border-bottom-style:solid;
	border-bottom-width:1px;
	border-bottom-color:#efefef;
}

.nav.navbar-nav .dropdown .dropdown-menu li a:hover { background-color:#f4f4f4; color:#868686; }

/** DROPDOWN SUB-MENU **/
.nav.navbar-nav .dropdown .dropdown-menu .dropdown-submenu {
        position:relative;
        overflow: visible;
    }

    .nav.navbar-nav .dropdown .dropdown-menu .dropdown-submenu > .dropdown-menu {
        left:161px;
        margin-top:-39px;
        margin-left:-1px;
        -webkit-border-radius:0px;
        -moz-border-radius:0px;
        border-radius:0px;
        display:none;
        background-color:white;
    }
    
    .nav.navbar-nav .dropdown .dropdown-menu .dropdown-submenu:hover > .dropdown-menu {
        display:block;
    }

/** MEGA MENU **/
.nav.navbar-nav .dropdown .dropdown-menu .yamm-content h4 a {
	font-family:Proxima Nova Light; 
	font-size:15px; 
	color:#868686;
}

.nav.navbar-nav .dropdown .dropdown-menu .yamm-content a {
	padding:0px;
	border:none;
	color:$primary-dark-color;
}

.nav.navbar-nav .dropdown .dropdown-menu .yamm-content a:hover {
	background:transparent;
	color:$primary-dark-color;
}

.googleMap { border:none; width:100%; }

/* LOGIN/REGISTER */
/**************************/
.userButtons { 
	margin-right:-15px; 
	padding-left:25px; 
	float:right; 
	border-left:1px solid #dcdcdc; 
	margin-bottom:0px !important;
}

.userButtons li { display:inline-block; }
.userButtons .userBtn { padding:25px 0px 25px 0px; }


/** HEADER 2 **/
.header2 .topBar {
	background:#2f2f2f;
	border-bottom:1px solid #e8e8e8;
	background: -webkit-linear-gradient(#3f3f3f, #2a2a2a); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(#3f3f3f, #2a2a2a); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(#3f3f3f, #2a2a2a); /* For Firefox 3.6 to 15 */
	background: linear-gradient(#3f3f3f, #2a2a2a); /* Standard syntax */
}

.header2 .socialIcons li { border:none; }

/** HEADER 3 **/
.header3 .socialIcons li a {
	font-family:Proxima Nova Semi-bold;
	color:#606060;
	font-size:12px;
}

.header3 .socialIcons li:first-child { border:none; }
.header3 .verticalDivider { background-color:#e9e9e9; }


/*====================================================================================*/
 /* 04 SUB-HEADERS 
/*====================================================================================*/

/** SUBHEADER HOME **/
.subHeader.home { margin-top:108px; }

.subHeader.home.bxslider .container { padding:90px 0px 150px 0px; }

#slide1 {
	background-image: url('../images/1600x670.gif');
	background-repeat:no-repeat;
	background-size:cover;
}

#slide2 {
	background-image: url('../images/1600x670.gif');
	background-repeat:no-repeat;
	background-size:cover;
}

#slide3 {
	background-image: url('../images/1600x670.gif');
	background-repeat:no-repeat;
	background-size:cover;
}

.subHeader.home.headerMinimal {
	margin-top:71px;
}

.subHeader.home h1 {
	display:inline;
	font-family:Proxima Nova Light;
	font-size:59px;
	background:rgba(225, 225, 225, 0.8);
	padding:5px 10px 0px 10px;
	line-height:80px;
}

.subHeader.home h1 span { font-family:Proxima Nova Semi-bold; }

.sliderTextBox {
	background:rgba(225, 225, 225, 0.8);
	padding:10px 15px 15px 15px;
	font-size:19px;
	
}

.sliderTextBox p { margin-bottom:20px; }

.sliderTextBox .buttonGrey.large { border-color:#bdbdbd; display:inline-block; }

.sliderTextBox .buttonColor { padding:10px 30px 10px 12px !important; display:inline-block; }

.icon-button-arrow {
	margin-top:-9px;
	width:29px;
	height:19px;
	background-image: url('../images/icon-button-arrow.png');
	background-position:0px 10px;
	background-repeat:no-repeat;
	border-right:1px solid #c6c6c6;
	padding:20px 10px 20px 0px;
	position:absolute;
}

.icon-button-user {
	margin-top:-10px;
	width:29px;
	height:19px;
	background-image: url('../images/icon-button-user.png');
	background-position:0px 10px;
	background-repeat:no-repeat;
	border-right:1px solid #629125;
	padding:20px 10px 19px 0px;
	position:absolute;
}

.buttonText { margin-left:50px; }

.subHeader.home .sliderPrice { 
	font-size:50px;
	float:right; 
	line-height:55px;
	margin-top:190px;
}

.or { 
	font-family:Proxima Nova Semi-bold;
	font-size:13px;
	padding:10px 30px 10px 30px;
	margin:0px 10px 0px 10px;
	background-image: url('../images/arrow.png');
	background-repeat:no-repeat;
	background-size:75px;
}

.sliderControls {
	margin-top:300px; 
	position:absolute; 
	width:100%;
}

.sliderControls span { 
	margin-top:0px; 
	opacity:0.5; 
	position:relative;
	z-index:1000;
	-webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.sliderControls span:hover { opacity:1.0; }
.sliderControls .slider-prev { float:left; margin-left:15px; }
.sliderControls .slider-next { float:right; margin-right:15px; }

/** SUBHEADER MAP **/
.subHeader.map { margin-top:108px; width:100%;  }

#map-canvas { width:100%; height:675px; position:absolute; }

.mapFilter { padding:70px 15px 70px 15px; }

.info-box { width:210px; height:270px; }

/** SUBHEADER PAGE **/
.subHeader.page {
	margin-top:108px;
	padding:65px 0px 65px 0px;
	background-color:$primary-light-color;
	//background-image: url('../images/subHeader-page-bg.jpg');
	background-repeat:no-repeat;
	background-size:cover;
}

.subHeader.page h1 {
	float:left;
	font-family:Proxima Nova Light;
	background:rgba(225, 225, 225, 0.7);
	padding:0px 10px 0px 10px;
	line-height:67px;
	display:inline;
}

.searchForm input {
	margin-top:18px;
	padding-right:30px;
	width:200px;
	float:right;
	border:none;
	box-shadow:none;
	background:rgba(225, 225, 225, 0.8);
	background-image: url('../images/icon-search.png');
	background-repeat:no-repeat;
	background-position: right 10px center;
}

.top-page {
	margin-top:100px;
}


/*====================================================================================*/
 /* 05 FILTER
/*====================================================================================*/

.filter { margin-top:-68px; position:relative; z-index:1; }

.filterHeader {
	background-color:$secondary-action-color;
	height:68px;
}

.filterNav { float:left; padding:23px; padding-left:0px; }

.filterNav li { display:inline; }

.filterNav li a {
	font-family:Proxima Nova Semi-bold;
	color:white;
	padding:24px 30px 23px 30px;
	text-decoration:none;
	outline:none;
}

.filterNav .current {
  position:relative;
  background-color:$dark-secondary-action;
}

.filterNav .current:after {
  content:"";
  position:absolute;
  bottom:-15px; /* value = - border-top-width - border-bottom-width */
  left:50%; /* controls horizontal position */
  margin-left:-15px;
  border-width:15px 15px 0; /* vary these values to change the angle of the vertex */
  border-style:solid;
  border-color:$secondary-action-color transparent;
  /* reduce the damage in FF3.0 */
  display:block;
  width:0;
  z-index:1;
}

.filterHeadButton {
	float:right;
	background-color:$secondary-action-color;
	padding:23px;
}

.filterContent {
	display:none;
	padding:23px;
	border-radius:5px;
	border-top-left-radius:0px;
	border-top-right-radius:0px;
	border:1px solid #c9c9c9;
	border-top:none;
	box-shadow: inset 0 -1px 1px white;
	background-color:#f2f2f2;
	background: -webkit-linear-gradient(#f8f8f8, #f2f2f2); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(#f8f8f8, #f2f2f2); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(#f8f8f8, #f2f2f2); /* For Firefox 3.6 to 15 */
	background: linear-gradient(#f8f8f8, #ebebeb); /* Standard syntax */
}

.filterContent.defaultTab { display:block; }

.formBlock {
	float:left;
	margin-right:40px;
	width:100%;
}

.priceSlider { margin-top:-12px; margin-bottom:7px; height:9px !important; }

.priceInput {
	float:left; 
	height:20px;
	background:#e7e7e7; 
	border-radius:5px; 
	padding:0px 3px 0px 3px; 
	font-size:13px; 
}

.priceInput input {
	border:none;
	box-shadow:none;
	width:60px;
	padding:0;
}

.noUi-connect { background-color:$secondary-action-color !important; }
.noUi-handle { 
	border-radius:80px !important; 
	cursor:pointer !important; 
	width:20px !important; 
	height:20px !important;
}
.noUi-handle:after,.noUi-handle:before { display:none !important; }

.priceSliderLabel {
	font-size:12px;
	color:#b9b9b9;
	margin-bottom:19px;
}


/*====================================================================================*/
 /* 06 BIG MESSAGE 
/*====================================================================================*/
.bigMessage {
	padding:70px 0px 70px 0px;
}

.bigMessage h1 {
	text-align:center;
	font-family:Proxima Nova Light;
	font-size:40px;
	margin:0px;
}

.bigMessage h1 span { font-family:Proxima Nova Semi-bold; }

.bigMessage p {
	font-size:19px;
	text-align:center;
}


/*====================================================================================*/
 /* 07 PROPERTIES
/*====================================================================================*/

/** PROPERTIES GLOBAL & HEADER **/
.properties {
	padding:70px 0px 70px 0px;
	background-color:#f5f5f5;
	border:1px solid #dedede;
	box-shadow: inset 0 1px 1px white;
}

.propertyCat_list { background-color:#e1e1e3; width:323px; margin-bottom:12px; }
.propertyCat_list li { display:inline-block; }

.propertyCat_list li a { 
	font-family:Proxima Nova Semi-bold;
	color:#a2a2a2; 
	padding:13px 25px 13px 25px; 
	display:inline-block;
}

.propertyCat_list li a:hover { color:$primary-dark-color; }

.propertyCat_list li .current {
	position:relative;
	color:white;
	background-color:$primary-dark-color;
}

.propertyCat_list li .current:after {
  content:"";
  position:absolute;
  bottom:-10px; /* value = - border-top-width - border-bottom-width */
  left:38%; /* controls horizontal position */
  border-width:10px 10px 0; /* vary these values to change the angle of the vertex */
  border-style:solid;
  border-color:$primary-dark-color transparent;
  /* reduce the damage in FF3.0 */
  display:block;
  width:0;
  z-index:1;
}

.propertyCat_list li .current:hover { color:white; }

.propertySort_list { margin-top:-55px; margin-bottom:53px; }

.propertySort_list li {
	float:right;
	display:inline;
	padding:10px;
}


/** PROPERTIES GRID **/
.propertyItem {
	background-color:white;
	box-shadow: 0px 1px 3px #d4d4d4;
	margin-bottom:30px;
}

.propertyContent { padding:9px; }
.propertyContent a { color:#2a2a2a; }
.propertyContent a:hover { color:$primary-dark-color; }

.propertyImg {
	max-width:100%;
	margin-bottom:15px;
}

.propertyImgLink {
	-webkit-transition: opacity 0.3s linear;
    -moz-transition: copacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.propertyImgLink:hover { opacity:0.8; }

.propertyType {
	position:absolute;
	font-family:Proxima Nova Semi-bold;
	font-size:12px;
	background-color:$primary-dark-color;
	color:white !important;
	padding:7px;
	z-index:1;
}

.openHouse {
	position:absolute;
	font-family:Proxima Nova Semi-bold;
	color:white;
	font-size:13px;
	right:7px;
	background-image: url('../images/open-house-bg.png');
	background-repeat:no-repeat;
	padding:19px 13px 15px 15px;
	z-index:1;
}

.forSale {
	float:left;
	font-family:Proxima Nova Semi-bold;
	font-size:15px;
	background-color:#d5d5d5;
	color:white;
	padding:4px 9px 4px 9px;
	border-radius:4px;
	display:inline-block;
}

.price {
	font-family:Proxima Nova Semi-bold;
	float:right;
	color:$primary-dark-color;
	font-size:23px;
}

.propertyDetails { 
	background-color:#f8f8f8;
	margin-top:0px; 
	border:1px solid #cacaca;
	width:100%;
	text-align:center;
}

.propertyDetails td {
	font-family:Proxima Nova Semi-bold;
	font-size:12px;
	width:33.6%;
	height:45px;
	border-color:#cacaca;
	color:#ababab;
}

.pageList { text-align:center; margin-top:20px; }
.pageList li { display:inline; }
.pageList li .current { background-color:$primary-dark-color; color:white; font-family:Proxima Nova Semi-bold; }
.pageList li a {
	color:#a1a1a1;
	padding:4px 10px 4px 10px;
	border-radius:3px;
	background-color:#dcdcdc;
}

.pageList li a:hover { 
	background-color:$primary-dark-color; 
	color:white; 
	-webkit-transition: background 0.2s linear;
    -moz-transition: background 0.2s linear;
    -ms-transition: background 0.2s linear;
    -o-transition: background 0.2s linear;
    transition: background 0.2s linear;
}

.masonryRow .col-lg-3 { width:292px; } 
.masonryRow .col-lg-3.wide { width:583px; }

/** PROPERTIES ROW **/
.rowText { padding-top:17px; padding-right:33px; }
.rowText h4 { float:left; font-size:19px; margin-bottom:0px; }
.rowText .price { float:right; }
.rowText .forSale { float:right; margin-right:20px; }
.propertyImgRow { max-width:100%; }

/** PROPERTY SINGLE **/
.gallery .sliderControls span { 
	margin-top:-420px; 
	opacity:0.6; 
	-webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.gallery:hover .sliderControls span { opacity:1.0; }
.gallery .sliderControls .slider-prev { float:left; margin-left:35px; }
.gallery .sliderControls .slider-next { float:right; margin-right:60px; }
.gallery #bx-pager { 
	text-align:center;
	background-color:white; 
	padding:10px;
	margin-bottom:40px;
 }

.gallery #bx-pager a img { opacity:0.6; }
.gallery #bx-pager a.active img { opacity:1.0; }

.overview {
	background-color:#f8f8f8;
	padding:10px;
	border:1px solid #d6d6d6;
	border-radius:5px;
	margin-bottom:30px;
}

.overviewList li { 
	padding-top:7px;
	padding-bottom:7px; 
	border-bottom:1px solid #dcdcdc;
}

.overviewList li:last-child { border:none; padding-bottom:0px; }

.overviewList span { 
	font-size:13px;
	font-family:Proxima Nova Semi-bold;
	float:right; 
	color:$primary-dark-color;
} 

.amentitiesTable { width:100%; }
.amentitiesTable td { width:27%; height:43px; }

.mapSmall { 
	max-width:100% !important; 
	height:200px !important; 
	position:relative !important; 
	margin-bottom:30px; 
}

/** SUBMISSIONS **/
.sidebarWidget.submission { height:600px; }

.myProperties { width:100%; }
.myProperties td.myPropertyImg { width:15%; }
.myPropertyAddress { width:20%; }
.myPropertyType { width:15%; }
.myPropertyStatus { width:11%; }
.myPropertyDate { width:15%; }
.myPropertyActions { width:24%; }
.myPropertyActions .icon { margin-right:5px; }

.myProperties .smallThumb { width:50%; }

.myProperties tr { border-bottom:1px solid #d8d8d8; }
.myProperties td { padding:10px 0px 10px 0px; }

.myProperties span { 
	font-family:Open Sans;
	font-size:12px;
	margin-right:27px; 
	float:left; 
}

.myPropertiesHeader { 
	font-family:Open Sans; 
	font-size:13px; 
}

.amenityCheck { margin-bottom:4px; }
.amenityCheckList input { float:left; width:20px; margin-right:5px; }
.amenityCheckList div label { 
	float:left;
	font-family:Proxima Nova Light; 
	font-weight:normal; 
	font-size:15px; 
	color:#464646;
}


/*====================================================================================*/
 /* 08 SERVICES
/*====================================================================================*/

.services { padding:70px 0px 70px 0px; text-align:center; }

.services .col-lg-4.col-md-4.col-sm-4 { margin-bottom:30px; }

.services h1 {
	text-align:center;
	font-family:Proxima Nova Light;
	font-size:40px;
	margin:0px;
}

.services h1 span { font-family:Proxima Nova Semi-bold; }

.serviceArrow {
	position:absolute;
	margin-left:35%;
	margin-top:-263px;
	opacity:0.6;
}

/*====================================================================================*/
 /* 09 AGENTS
/*====================================================================================*/

/** TOP AGENTS **/
.topAgents {
	padding:70px 0px 70px 0px;
	color:white;
	text-align:center;
	background-image: url('../images/topAgents-bg.jpg');
	background-repeat:no-repeat;
	background-attachment:fixed;
	background-size:cover;
}

.topAgents .col-lg-3.col-md-3.col-sm-3 { margin-bottom:60px; }

.agentImg { max-width:100%; }

.topAgents .socialIcons, .socialIcons.agent { float:none; }
.topAgents .socialIcons li, .socialIcons.agent li { border:none; padding-left:3px; }
.topAgents .socialIcons li a {
	background-color:white;
	padding:2% 3% 2% 3%;
	border-radius:4px;	
}

.topAgents .socialIcons li a:hover { background-color:#393939; }

/** AGENT LISTING GRID **/
.agentDetails { 
	background-color:#f8f8f8;
	margin-top:0px; 
	border:1px solid #cacaca;
	width:100%;
}

.agentDetails td {
	font-family:Proxima Nova Semi-bold;
	font-size:12px;
	width:50%;
	height:50px;
	border:none;
	color:#ababab;
	text-align:right;
	padding:9px;
}

.agentDetails .buttonGrey {
	float:left;
	padding:6px 14px 6px 14px !important;
}

/** AGENT LISTING ROW **/
.rowText.agentRow .buttonGrey { float:right; }
.rowText.agentRow .socialIcons { float:left; }
.rowText.agentRow .socialIcons li { border:none; }


/*====================================================================================*/
 /* 10 BLOG
/*====================================================================================*/

/** RECENT BLOG POSTS WIDGET **/
.recentBlogPost { display:inline-block; }
.recentBlogPost h4 a { color:#2a2a2a; }
.recentBlogPost h4 a:hover { color:$primary-dark-color; }

.blogThumb { float:left; margin-right:20px; }

.recentBlogContent { float:left; width:76%; }
.recentBlogContent p { margin-bottom:15px; }

.date { float:right; }

/** BLOG CLASSIC **/
.blogPost {
	background-color:white;
	box-shadow: 0px 1px 3px #d4d4d4;
	margin-bottom:30px;
}

.blogPost .rowText { padding:30px; }

.blogDetails { 
	margin-top:-10px; 
	margin-bottom:25px;
	border:1px solid #cacaca;
	width:100%;
	text-align:center;
}

.blogDetails td {
	font-family:Proxima Nova Semi-bold;
	font-size:12px;
	width:25%;
	height:45px;
	border-color:#cacaca;
}

.blogDetails td a { color:#ababab; }

.blogPost h4 { font-family:Proxima Nova Light; font-size:19px;}
.blogPost h5 { 
	font-family:Proxima Nova Semi-bold; 
	font-size:16px; 
	margin-top:25px;
}

.blogPost span {
	font-family:Proxima Nova Semi-bold;
	font-size:12px;
	color:#939393;
	line-height:33px;
	display:inline-block;
	margin-bottom:20px;
}

.blogPost span a { color:$primary-dark-color; }

/** BLOG SINGLE **/
.quote {
	font-family:Proxima Nova Semi-bold;
	color:#9a9a9a;
	font-size:16px;
	background-color:#f5f5f5;
	padding:27px;
	width:82%;
	margin:30px 0px 35px 9%;
	border-left:4px solid #514f8b;
}

.arrow-left {
	width: 0; 
	height: 0; 
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent; 
	border-right:8px solid #514f8b; 
	float:left;
	margin-top:50px;
	margin-left:-8px;
}

.commentList { margin-bottom:20px; }

.commentImg { max-width:100%; }

.commentText { 
	padding:14px;
	padding-bottom:30px;
	background-color:white; 
	border:1px solid #c9c9c9;
	border-left:4px solid #514f8b;
	border-radius:5px;
	border-top-left-radius:0px;
	border-bottom-left-radius:0px;
	margin-bottom:20px;
}

.commentText h1 {
	font-family:Proxima Nova Semi-bold;
	font-size:14px;
	color:#514f8b;
}

.commentText p { font-size:14px; }

.commentText .commentFooter {
	float:left;
	font-family:Proxima Nova Semi-bold;
	font-size:11px;
	color:#ababab;
}

.commentText .commentFooter.replyIcon:hover { opacity:0.7; }

.commentText .commentFooter.replyIcon { float:right; }

.comment.reply { margin-left:145px; }

/*====================================================================================*/
 /* 11 PAGES
/*====================================================================================*/

/** ABOUT **/
.topAgents.about { 
	background:none; 
	padding-top:0px; 
	color:#464646;
}

.topAgents.about h4 { color:$primary-dark-color; }

.callToAction.about { padding-top:70px; }

/** FAQ **/
.accordianTab {
	margin-bottom:20px;
	background:white;
	padding:10px;
	border-radius:5px;
	border:1px solid #dadada;
}

.accordianLink {
	background-image: url('../images/icon-plus.png');
	background-position:4px 9px;
	background-repeat:no-repeat;
	padding-left:35px;
}

.accordianLink p { padding-top:9px; }

.accordianTab .accordianLink { cursor:pointer; }

.accordianTab .accordianLink:hover { color:$primary-dark-color; }

.accordianContent { display:none; padding:15px 20px 0px 20px; }

/** 404 **/
.error-404 {
	background:url('../images/icon-search-big.png');
	background-repeat:no-repeat;
	background-position:56% 0;
	text-align:center;
	padding-bottom:20px;
	padding-top:50px;
	margin-top:20px;
}

.error-404 h1 {
	font-family:Proxima Nova Semi-bold;
	font-size:85px;
	color:$primary-dark-color;
}

.error-404 p { font-size:21px; }

/*====================================================================================*/
 /* 12 CONTACT
/*====================================================================================*/
.contactDetails { margin-bottom:40px; }
.contactDetails li {
	display:inline;
	margin-right:40px;
}

.contactDetails li img { margin-right:13px; }

.mapContact { 
	max-width:100% !important; 
	height:330px !important; 
	position:relative !important;  
}

.contactImg { max-width:50%; float:left; margin-right:20px; }

/*====================================================================================*/
 /* 13 CALL TO ACTION
/*====================================================================================*/

.callToAction { padding:0px 0px 70px 0px; }

.ctaBox { 
	background-color:#ebebeb;
	padding:30px 25px 30px 15px;
	border-radius:5px;
}

.callToAction h1 { font-family:Proxima Nova Light; font-size:32px; }
.callToAction h1 span { font-family:Proxima Nova Semi-bold; }

.callToAction p { font-size:17px; }

/*====================================================================================*/
 /* 14 SIDEBAR
/*====================================================================================*/

.sidebarWidget {
	margin-bottom:40px;
	background:white;
	padding:15px;
	border-radius:5px;
	border:1px solid #dadada;
}

.sidebarWidget h4 a, .sidebarWidget.recentPosts h4 a { 
	font-family:Proxima Nova Light; 
	font-size:15px; 
	color:#868686;
}

.sidebarWidget h4 a:hover { color:$primary-dark-color; }

.sidebarWidget.recentPosts a {
	font-family:Proxima Nova Semi-bold;
	font-size:12px; 
	color:$primary-dark-color; 
	float:left;
}

.sidebarWidget.recentPosts .date { 
	font-size:12px; 
	font-family:Proxima Nova Semi-bold;  
	color:#acacac;
}

.propertyTypesWidget ul li h4 { margin:0; }

.propertyTypesWidget ul li {
	padding:5px 0px 5px 0px;
	border-bottom:1px solid #dadada;
}

/*====================================================================================*/
 /* 15 SHORTCODES
/*====================================================================================*/

/** TABS **/
.tabSmall li { display:inline; }
.tabSmall li a.current { 
	font-family:Open Sans;
	color:white;
	border:1px solid $border-color;
	box-shadow: inset 0 1px 1px white;
	text-shadow:0px -1px 3px #0e4562;
	background-color:$secondary-action-color;
	background: -webkit-linear-gradient($secondary-action-color, $light-secondary-action); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient($secondary-action-color, $light-secondary-action); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient($secondary-action-color, $light-secondary-action); /* For Firefox 3.6 to 15 */
	background: linear-gradient($secondary-action-color, $light-secondary-action); /* Standard syntax */
}

.tabSmall .buttonGrey {
	border-bottom-right-radius:0px;
	border-bottom-left-radius:0px;
}

.tabConent {
	display:none;
	margin-top:-6px;
	border-top-left-radius:0px;
}

.tabConent.defaultTab { display:block; }

/** ALERT BOXES **/

.alertBox {
	border-style:solid;
	border-radius:3px;
	padding:18px 18px 8px 18px;
	margin-bottom:15px;
}

.alertBox img { float:left; margin-right:10px; margin-top:-3px; }

.success { 
	background-color:#e2f1db;
	border-color:#8dca70;
	color:#73a45b;
}

.success h4 { color:#73a45b; }

.error {
	background-color:#f1d9d9;
	border-color:#c44747;
	color:#c44747;
}

span.error { 
	display:inline-block; 
	margin-bottom:25px; 
	padding:0px 3px 0px 3px;
	border-radius:3px;
}

.error h4 { color:#c44747; }

.info {
	background-color:#d9e7f1;
	border-color:#70a3ca;
	color:#70a3ca;
}

.info h4 { color:#70a3ca; }

.warning {
	background-color:#ebe8bc;
	border-color:#aca530;
	color:#aca530;
}

.warning h4 { color:#aca530; }

/** PRICING TABLE **/
.pricingTable {
	margin-top:-15px;
	padding:0px;
	text-align:center;
	border-radius:5px;
	border-style:solid;
	border-width:1px;
	border-color:#c9c9c9;
	-webkit-box-shadow: 0px 0px 4px #d6d6d6;
    -moz-box-shadow: 0px 0px 4px #d6d6d6;
    box-shadow: 0px 0px 4px #d6d6d6;
    margin-bottom:30px;
}

.pricingTable:hover {
	margin-top:-25px;
	animation-duration: 0.55s;
  	animation-name: slideUpPrice;
  	animation-direction: normal;
  	animation-iteration-count: 1;
}

/* animation */
@keyframes slideUpPrice {
  from {
    margin-top: -20px;
  }

  to {
    margin-top: -25px;
  }
}

.pricingHeader { padding:11px 0px 18px 0px; }

.pricingTable h1 {
	font-family:Open Sans;
	color:$primary-dark-color;
	font-size:20px;
}

.pricingTable h2 {
	font-family:Open Sans;
	color:white;
	font-size:48px;
	line-height:25px;
	-webkit-text-shadow:0px 0px 3px #202020;
	-moz-text-shadow:0px 0px 3px #202020;
	text-shadow:0px 0px 3px #202020;
}

.priceAmount {
	margin-top:-19px;
	background-color:$primary-dark-color;
	padding:28px 0px 10px 0px;
}

.priceAmount h2 span {
	font-size:16px;
	font-style:italic;
}

.pricingTable ul li {
	list-style:none;
	line-height:50px;
	background-image: -webkit-linear-gradient(bottom, rgba(0,0,0,.05), rgba(2,2,2,0)); 
	background-image: -moz-linear-gradient(bottom, rgba(0,0,0,.05), rgba(2,2,2,0)); 
	background-image: -o-linear-gradient(bottom, rgba(0,0,0,.05), rgba(2,2,2,0)); 
	background-image: -ms-linear-gradient(bottom, rgba(0,0,0,.05), rgba(2,2,2,0)); 
	background-image: linear-gradient(to top, rgba(0,0,0,.04), rgba(2,2,2,0));
	border-bottom-style:solid;
	border-bottom-width:1px;
	border-bottom-color:#d3d3d3;
}

.pricingTable ul li:last-child { border-bottom:none; line-height:70px; background:none; }

/*====================================================================================*/
 /* 16 FOOTER
/*====================================================================================*/

footer {
	padding:70px 0px 50px 0px;
	background-color:$primary-light-color;
	color:$primary-text-color;
}

footer .col-lg-3.col-md-3 { margin-bottom:30px; }

.footerLogo { font-family:Proxima Nova Light; color:white; font-size:21px; }
.footerLogo span { font-family:Proxima Nova Semi-bold; color:$secondary-action-color; font-size:21px; }
.footerLogo img { margin-right:15px; }

footer h4 { color:white; margin-bottom:20px; }

footer span {
	font-family:Proxima Nova Semi-bold;
	font-size:11px;
}

.contactList li {
	margin-bottom:17px;
}

footer .icon { padding-top:3px; }

footer input { 
	border:1px solid #4f4d6e; 
	width:61%; 
	float:left; 
	margin-right:8px; 
}

footer .buttonColor { 
	margin-top:-1px; 
	width:80px; 
	border-color:#4f4d6e;
	padding:10px 15px 10px 15px !important;
}

.bottomBar {
	font-family:Open Sans;
	padding-top:15px;
	padding-bottom:7px;
	font-size:11px;
	color:$muted-text-color;
	background-color:$primary-dark-color;
}

.bottomBar p { float:left; margin-top:6px; }

.bottomBar .socialIcons { margin-top:5px; }

.bottomBar .socialIcons li { border-color:#5b597c; }

#footer2 { text-align:center; }

.socialIcons.footer2 {float:none; }
.socialIcons.footer2 li {display:inline;  border:none; }
.socialIcons.footer2 li a {
	width:50px;
	height:50px;
	padding-top:10px;
	display:inline-block;
	border:3px solid #5b597c; 
	border-radius:75px;
}