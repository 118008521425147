/*====================================================================================*/
 /* 00 Variables
/*====================================================================================*/
 

$primary-dark-color: #084e87;
$primary-light-color: lighten( $primary-dark-color, 15% );

$nav-color: $primary-dark-color;

$secondary-action-color: #ff0000;
$light-secondary-action: lighten( $secondary-action-color, 10% );
$dark-secondary-action: darken( $secondary-action-color, 8% );
$light-secondary-action-hover: lighten( $secondary-action-color, 15% );

$border-color: #ccc;


$primary-text-color: #fff;
$muted-text-color: #bfbfbf;